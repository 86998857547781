<template>
  <div class="card mt-3">
    <div class="surface-card p-4 mt-4 shadow-2 border-round">
      <div class="grid formgrid p-fluid">
        <div class="field mb-4 col-12">
          <label for="clinica" :class="{'p-error': v$.clinical.name.$error}">Nome da Clínica*</label>
          <div class="p-inputgroup">
            <InputText id="clinica" type="text"
                       required="true"
                       maxlength="50"
                       @blur="v$.clinical.name.$touch"
                       autofocus
                       v-model="clinical.name"
                       :class="{'p-invalid': v$.clinical.name.$error}"
            />
          </div>
          <small class="p-error" v-for="error of v$.clinical.name.$errors"
                 :key="error.$uid">{{error.$message}}</small>
        </div>
        <div class="field-radiobutton col-2 md:col-2">
          <RadioButton inputId="pj" name="tipoClinica" value="pj" v-model="tipoClinica" />
          <label for="city2">Pessoa Jurídica</label>
        </div>
        <div class="field-radiobutton col-2 md:col-2">
          <RadioButton inputId="pf" name="tipoClinica" value="pf" v-model="tipoClinica" />
          <label for="city2">Pessoa Física</label>
        </div>
        <div class="field-radiobutton col-8 md:col-8"></div>
        <div class="field mb-4 col-12 md:col-3" v-if="tipoClinica === 'pj'">
          <label for="cnpj" :class="{'p-error': v$.clinical.cnpj.$error}">CNPJ*</label>
          <div class="p-inputgroup">
            <InputMask mask="99.999.999/9999-99"
                       id="cnpj"
                       @blur="v$.clinical.cnpj.$touch"
                       autofocus
                       :class="{'p-invalid': v$.clinical.cnpj.$error}"
                       v-model="clinical.cnpj"/>
          </div>
          <small class="p-error" v-for="error of v$.clinical.cnpj.$errors"
                 :key="error.$uid">{{error.$message}}</small>
        </div>
        <div class="field mb-4 col-12 md:col-3" v-if="tipoClinica === 'pf'">
          <label for="cpf" :class="{'p-error': v$.clinical.cpf.$error}">CPF*</label>
          <div class="p-inputgroup">
            <InputMask mask="999.999.999-99"
                       id="cpf"
                       @blur="v$.clinical.cpf.$touch"
                       autofocus
                       :class="{'p-invalid': v$.clinical.cpf.$error}"
                       v-model="clinical.cpf"/>
          </div>
          <small class="p-error" v-for="error of v$.clinical.cpf.$errors"
                 :key="error.$uid">{{error.$message}}</small>
        </div>
        <div class="field mb-4 col-12 md:col-3">
          <label for="celular" :class="{'p-error': v$.clinical.celular.$error}">Celular*</label>
          <div class="p-inputgroup">
            <InputMask
                mask="(99) 99999-9999"
                id="celular"
                @blur="v$.clinical.celular.$touch"
                autofocus
                :class="{'p-invalid': v$.clinical.celular.$error}"
                v-model="clinical.celular"/>
          </div>
          <small class="p-error" v-for="error of v$.clinical.celular.$errors"
                 :key="error.$uid">{{error.$message}}</small>
        </div>
        <div class="col-12 md:col-6"></div>
        <div class="field mb-4 col-9 md:col-3">
          <label for="cep" class="font-medium text-900">CEP</label>
          <InputMask mask="99999-999" id="cep" v-model="clinical.cep"/>
        </div>
        <div class="col-12 md:col-2 align-items-center flex">
          <Button icon="pi pi-search" label="Buscar" class="p-button" @click.prevent="findByCep"/>
        </div>
        <div class="field mb-4 md:col-7"></div>
        <div class="field mb-4 col-12 md:col-6">
          <label for="email2" class="font-medium text-900">Estado</label>
          <Dropdown v-model="uf" :options="ufs" optionLabel="sigla" placeholder="Selecione um Estado" />
        </div>

        <div class="field mb-4 col-12 md:col-6">
          <label for="email2" class="font-medium text-900">Cidade</label>
          <InputText id="email2" type="text" v-model="clinical.cidade"/>
        </div>
        <div class="field mb-4 col-12 md:col-5">
          <label for="email2" class="font-medium text-900">Bairro</label>
          <InputText id="email2" type="text" v-model="clinical.bairro"/>
        </div>
        <div class="field mb-4 col-12 md:col-5">
          <label for="email2" class="font-medium text-900">Logradouro</label>
          <InputText id="email2" type="text" v-model="clinical.logradouro"/>
        </div>
        <div class="field mb-4 col-12 md:col-2">
          <label for="email2" class="font-medium text-900">Número</label>
          <InputText id="email2" type="text" v-model="clinical.numero"/>
        </div>

        <div class="field mb-4 col-12">
          <label for="privacy2" class="font-medium text-900">Você esta de acordo com nossos termos e privacidade?</label>
          <div class="flex align-items-center">
            <InputSwitch id="privacy2"
                         v-model="clinical.acceptTerms"
            ></InputSwitch>
          </div>
        </div>
        <div class="col-12 text-right">
          <Button label="Próximo" icon="pi pi-angle-right" icon-pos="right" class="w-auto mt-3 text-right" @click.prevent="nextPage"></Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BrasilService from "@/services/BrasilService";
import _ from "lodash";
import CepService from "@/services/CepService";
import {useVuelidate} from "@vuelidate/core";
import {helpers, required, requiredIf} from "@vuelidate/validators";

export default {
  name: "Step1",
  setup: () => ({v$: useVuelidate()}),
  created() {
    this.brasilService = new BrasilService();
    this.cepService = new CepService();
  },
  data() {
    return {
      brasilService: null,
      cepService: null,
      clinical: {
        uf: null,
        cidade: null,
        acceptTerms: false
      },
      ufs: [],
      uf: null,
      local: null,
      tipoClinica: 'pj'
    }
  },
  validations() {
    return {
      clinical: {
        name: {
          required: helpers.withMessage('Campo Nome é obrigatório.', required)
        },
        cnpj: {
          requiredIf: helpers.withMessage('Campo CNPJ é obrigatório.', requiredIf(this.tipoClinica === 'pj'))
        },
        cpf: {
          requiredIf: helpers.withMessage('Campo CPF é obrigatório.', requiredIf(this.tipoClinica === 'pf'))
        },
        celular: {
          required: helpers.withMessage('Campo Celular é obrigatório.', required)
        }
      }
    }
  },
  async mounted() {
    await this.findAllUf();
  },
  methods: {

    async nextPage() {
      const isFormValid = await this.v$.$validate();
      if (isFormValid) {
        if(!this.clinical.acceptTerms) {
          this.$toast.add({severity:'warn', summary: 'Alerta', detail: 'Para continuar você precisa aceitar nossos Termos.', life: 3000});
          return;
        }
        this.loader = this.$loading.show();
        this.$emit('next-page', {
          formData: {
            clinical: this.clinical
          },
          pageIndex: 0
        });
        this.loader.hide();
      }
      return;
    },

    async findAllUf() {
      const ufss = [];
      const response = await this.brasilService.findEstados();
      if (response.status === 200) {
        response.data.forEach((uf) => {
          ufss.push(uf);
        });
        this.ufs = _.orderBy(ufss, ['sigla'], ['asc'])
      }
    },

    async findByCep() {
      const response = await this.cepService.findCep(this.clinical.cep);
      if (response.status === 200) {
        this.clinical.uf = response.data.state;
        this.clinical.cidade = response.data.city;
        this.clinical.bairro = response.data.neighborhood;
        this.clinical.logradouro = response.data.street;

        const resp = await this.brasilService.findById(response.data.state);
        this.uf = resp.data;
      }
    }
  }
}
</script>

<style scoped>

</style>
